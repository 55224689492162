<template>
    <div>
        <div v-if="activeCashbackBonus">
            <div class="caption mb-1">{{ activeCashbackBonus.name }}</div>
            <div class="caption">
                {{ $t('dashboard.sections.bonuses.labels.potentialCashback') }}:
                <span class="secondary--text">{{ formatMoney(activeCashbackBonus.potential_cashback) }}</span>
                {{ currency }}
            </div>
            <v-btn
                v-if="activeCashbackBonus.settings.cashback_request_mode"
                class="mt-2 tile-gradient-primary"
                @click="getCashback"
                small
                :disabled="loading">
                {{ $t("dashboard.sections.bonuses.getCashback") }}
            </v-btn>
        </div>
        <div v-else>
            {{ $t("dashboard.sections.bonuses.notActiveBonuses") }}
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex"
import axios from "axios"

export default {
    name: "ProfileDrawerBonusSection",
    data: () => ({
        loading: false
    }),
    computed: {
        ...mapState({
            showCryptoAsFiat: (state) => state.user.crypto.is_print,
            fiatCurrency: (state) => state.user.crypto.fiat_currency,
            cryptoCurrency: (state) => state.user.crypto.crypto_currency,
        }),
        activeCashbackBonus() {
            const bonus = this.$auth.user()?.active_bonus
            return bonus?.type === "cashback" ? bonus : null
        },
        currency() {
            return this.showCryptoAsFiat ? this.fiatCurrency : this.cryptoCurrency
        }
    },
    methods: {
        ...mapActions({
            makeActiveWindow: "app/makeActiveWindow"
        }),
        formatMoney(data) {
                const isCrypto = this.showCryptoAsFiat ? "fiat" : "crypto"
                const cashbackValue = data[isCrypto] / 100 || 0
                const decimalPlaces = isCrypto === "fiat" ? 2 : 4
                return cashbackValue.toFixed(decimalPlaces)
        },
        async getCashback() {
            this.loading = true
            try {
                await axios.get("/api/user-dashboard/get-cashback")
            } catch (error) {
                this.handleError(error)
            } finally {
                this.loading = false
            }
        },
        handleError(error) {
            const details = error.response?.data?.details

            if (!details) {
                console.error("An unknown error occurred", error)
                return
            }

            const errorHandlers = {
                "Threshold conditions not met.": () => {
                    this.makeActiveWindow({
                        type: "info",
                        data: {
                            active: true,
                            title: this.$t("window.info.bonusThresholdError.title"),
                            text: this.$t("window.info.bonusThresholdError.text"),
                            data: `${this.formatMoney(details.betsAmount)}/${this.formatMoney(details.threshold)} ${this.currency}`
                        }
                    })
                },
                "Your balance is too high.": () => {
                    this.makeActiveWindow({
                        type: "info",
                        data: {
                            active: true,
                            title: this.$t("window.info.highBalanceError.title"),
                            text: this.$t("window.info.highBalanceError.text"),
                            data: `${this.formatMoney(details.requiredMaxBalance)} ${this.currency}`
                        }
                    })
                }
            }

            const handle = errorHandlers[details.message]
            if (handle) {
                handle()
            } else {
                console.error("An unknown error occurred", error)
            }
        }
    }
}
</script>
