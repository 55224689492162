<template>
    <v-overlay v-if="confirmation" v-model="confirmation.active" z-index="203">
        <v-container max-width="600">
            <v-card class="pb-2 grey darken-4">
                <v-card-title class="headline mb-8">{{ confirmation.title }}</v-card-title>
                <v-card-text class="d-flex align-center">
                    <div class="text-subtitle-1">{{ $t('dashboard.sections.bonuses.notice') }}:</div>
                    <div class="text-subtitle-1 font-weight-bold ml-2">{{ confirmation.text }}!</div>
                </v-card-text>
                <v-card-actions>
                    <v-checkbox
                        dark
                        v-model="agree"
                        hide-details
                        class="mt-0"
                        :label="confirmation.agreement"
                    />
                    <v-spacer/>
                    <v-btn color="primary" :disabled="!agree" @click="closeWindow">
                        {{ $t("buttons.continue") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </v-overlay>
</template>

<script>

import {mapActions, mapState} from "vuex"

export default {
    name: "Confirmation",
    data() {
        return {
            agree: false
        }
    },
    computed: {
        ...mapState({ confirmation: state => state.app.windows.confirmation })
    },
    methods: {
        ...mapActions("app", ["makeActiveWindow"]),
        closeWindow() {
            this.agree = false
            this.makeActiveWindow({ active: false, type: "confirmation" })
        }
    }
}
</script>
