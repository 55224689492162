<template>
    <div class="dashboard-layout d-flex flex-grow-1 v-sheet">
        <navigation-drawer
            v-model="navigationDrawerActive"
            :carousel="quickNavItems"
            :mobile_menu="mobileMenuItems"
        />

<!--        <profile-drawer v-model="profileDrawerActive" />-->

        <toolbar
            @open-navigation="openNavigationDrawer"
            @open-profile="openProfileDrawer"
        />

        <v-main class="fill-height">
            <div class="dashboard__container">
                <!--                    <v-col cols="12" class="d-lg-none">-->
                <!--                        <breadcrumbs :pageTitle="this.$route.meta.title"/>-->
                <!--                    </v-col>-->

                <div class="d-none d-md-block">
                    <left-menu />
                </div>

                <div class="dashboard__content-wrapper">
                    <slot></slot>
                </div>

                <!--                    <v-col lg="auto" class="d-none d-lg-block">-->
                <!--                        <social/>-->
                <!--                        <articles/>-->
                <!--                    </v-col>-->
            </div>

            <app-footer v-if="layout.footer" />
        </v-main>
    </div>
</template>

<script>
import { mapState } from "vuex";
import NavigationDrawer from "@/components/navigation/NavigationDrawer";
import ProfileDrawer from "@/components/navigation/ProfileDrawer";
import Toolbar from "@/components/toolbar/Toolbar";
import FlagIcon from "@/components/common/FlagIcon";
import BottomBar from "@/components/navigation/BottomBar";
import AppFooter from "@/components/layout/footer/AppFooter";
import breaks from "@/mixins/breaks";
import LeftMenu from "@/components/user/LeftMenu";
import Breadcrumbs from "@/components/user/Breadcrumbs";
import Social from "@/components/user/Social";
import Articles from "@/components/user/Articles";

// navigation menu configurations
import config from "@/configs";

export default {
    components: {
        NavigationDrawer,
        ProfileDrawer,
        Toolbar,
        FlagIcon,
        BottomBar,
        AppFooter,
        LeftMenu,
        Breadcrumbs,
        Social,
        Articles
    },
    mixins: [breaks],
    data() {
        return {
            navigationDrawerActive: false,
            profileDrawerActive: false,
            navigation: config.navigation,
            locales: config.locales.availableLocales,
            searchQuery: null
        };
    },
    computed: {
        ...mapState({
            layout: state => state.app.layout,
            quickNavItems: state => state.app.controlContents.quickNav?.items,
            mobileMenuItems: state => state.app.controlContents.mobileMenuItems
        })
    },
    methods: {
        openNavigationDrawer() {
            this.navigationDrawerActive = true;
        },
        openProfileDrawer() {
            this.profileDrawerActive = true;
        },
        search() {
            // search action
        }
    }
};
</script>

<style lang="scss">
.dashboard-layout {
    overflow: hidden;

    .v-main__wrap {
        max-width: 100%;
        margin: 0 auto;
        //padding: 0 14px;

        @media screen and (max-width: 600px) {
            margin-top: 35px;
        }

        @media screen and (min-width: 1300px) {
            max-width: 1300px;
        }
    }

    .dashboard__container {
        display: flex;
        flex-wrap: nowrap;
    }

    .dashboard__content-wrapper {
        //margin-left: 16px;
        overflow-x: hidden;
        max-width: 100%;
        flex-grow: 1;

        @media screen and (min-width: 1600px) {
            max-width: 1600px;
        }
    }

    .dashboard__title {
        color: var(--v-text-base);
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px;
    }
}

.user-dashboard-dialog {
    .v-toolbar__content {
        display: flex !important;
    }
}
</style>
