<template>
    <v-app-bar
        app
        :height="height.main"
        :extension-height="height.extension"
        class="main-toolbar elevation-0 mobile-toolbar"
        style="z-index: 91; background-color: var(--v-header-base);"
    >
        <template>
            <div>
<!--                <div v-if="isDesktop" class="app-bar__top">-->
<!--                    <div style="font-size: 11px; font-weight: 700;">-->
<!--                        {{ $t("common.topleftText") }}-->
<!--                    </div>-->

<!--                    <div>-->
<!--                        <router-link-->
<!--                            style="font-size: 9px; font-weight: 700; color: #fff; margin-right: 24px;"-->
<!--                            to="/"-->
<!--                            >{{ $t("common.live-help") }}</router-link-->
<!--                        >-->
<!--                        <router-link-->
<!--                            style="font-size: 9px; font-weight: 700; color: #fff"-->
<!--                            to="/"-->
<!--                            >{{ $t("common.help") }}</router-link-->
<!--                        >-->
<!--                    </div>-->
<!--                </div>-->

                <div class="d-flex justify-space-between align-center">
                    <!--            <div class="width-wrapper">-->

<!--                    <v-menu-->
<!--                        v-if="authUser && isDesktop"-->
<!--                        v-model="isActiveBurgerMenu"-->
<!--                        :close-on-content-click="false"-->
<!--                        offset-y-->
<!--                        transition="slide-y-transition"-->
<!--                    >-->
<!--                        <template v-slot:activator="{ on, attrs }">-->
<!--                            <button-->
<!--                                v-if="authUser"-->
<!--                                type="button"-->
<!--                                class="main-toolbar__burger"-->
<!--                                v-on="on"-->
<!--                                v-bind="attrs"-->
<!--                            >-->
<!--                                <svg-->
<!--                                    class="svg-icon svg-icon&#45;&#45;responsive"-->
<!--                                    viewBox="0 0 36 36"-->
<!--                                    fill="none"-->
<!--                                    xmlns="http://www.w3.org/2000/svg"-->
<!--                                >-->
<!--                                    <path-->
<!--                                        style="fill:#D7DAE0;"-->
<!--                                        d="M4.5 27V24H31.5V27H4.5ZM4.5 19.5V16.5H31.5V19.5H4.5ZM4.5 12V9H31.5V12H4.5Z"-->
<!--                                    />-->
<!--                                </svg>-->
<!--                            </button>-->
<!--                        </template>-->
<!--                        <left-menu @click="closeBurgerMenu" />-->
<!--                    </v-menu>-->

<!--                    <button-->
<!--                        v-else-if="authUser && isMobile"-->
<!--                        type="button"-->
<!--                        class="main-toolbar__burger"-->
<!--                        @click.stop="openNavigation"-->
<!--                    >-->
<!--                        <svg-->
<!--                            class="svg-icon svg-icon&#45;&#45;responsive"-->
<!--                            viewBox="0 0 36 36"-->
<!--                            fill="none"-->
<!--                            xmlns="http://www.w3.org/2000/svg"-->
<!--                        >-->
<!--                            <path-->
<!--                                style="fill:#D7DAE0;"-->
<!--                                d="M4.5 27V24H31.5V27H4.5ZM4.5 19.5V16.5H31.5V19.5H4.5ZM4.5 12V9H31.5V12H4.5Z"-->
<!--                            />-->
<!--                        </svg>-->
<!--                    </button>-->

                    <div
                        class="d-flex flex-grow-1 align-center justify-space-between"
                    >
                        <logo-responsive />

                        <!--                        <v-spacer v-if="mdAndDown"/>-->

                        <div class="main-toolbar__buttons">
<!--                            <toolbar-chat-->
<!--                                v-if="authUser"-->
<!--                                :cssClass="'none-400'"-->
<!--                            />-->
<!--                            <toolbar-deposit-withdrawal-->
<!--                                v-if="authUser"-->
<!--                                :deposit="true"-->
<!--                                :withdrawal="false"-->
<!--                                :cssClass="'none-400'"-->
<!--                            />-->

<!--                            <v-spacer-->
<!--                                v-if="authUser"-->
<!--                                class="header-spacer ml-1 ml-sm-0 none-400"-->
<!--                            />-->

                            <toolbar-bonus v-if="authUser && hasCorrectFreeSpinBonusWalletCombination" />

                            <toolbar-balance-static
                                :key="bonusUpdateKey"
                                v-if="authUser && !settings.crypto"
                                css-class="mx-2"
                            />

                            <toolbar-crypto-balance
                                v-if="authUser"
                                cssClass="mx-2"
                            />

<!--                            <v-tooltip v-if="freebetBonus" bottom>-->
<!--                                <template v-slot:activator="{ on, attrs }">-->
<!--                                    <v-icon-->
<!--                                        class="cashback-icon"-->
<!--                                        v-bind="attrs"-->
<!--                                        v-on="on"-->
<!--                                        @click="getFreebetInfo()"-->
<!--                                    >-->
<!--                                        mdi-gift-->
<!--                                    </v-icon>-->
<!--                                </template>-->
<!--                                <span>{{ $t("common.freebetBonus") }}</span>-->
<!--                            </v-tooltip>-->

<!--                            <v-tooltip v-if="freespinBonus" bottom>-->
<!--                                <template v-slot:activator="{ on, attrs }">-->
<!--                                    <v-icon-->
<!--                                        class="cashback-icon"-->
<!--                                        v-bind="attrs"-->
<!--                                        v-on="on"-->
<!--                                        @click="getFreespinInfo()"-->
<!--                                    >-->
<!--                                        mdi-slot-machine-->
<!--                                    </v-icon>-->
<!--                                </template>-->
<!--                                <span>{{ $t("common.freespinBonus") }}</span>-->
<!--                            </v-tooltip>-->

                            <toolbar-crypto-wallet-links
                                v-if="settings.crypto"
                            />

                            <toolbar-user
                                v-if="
                                    (authUser && !profileDrawerStyle) ||
                                        (authUser &&
                                            profileDrawerStyle === 'classic')
                                "
                                @open="openProfile"
                            />

                            <toolbar-user-modern
                                v-if="
                                    authUser &&
                                        profileDrawerStyle &&
                                        profileDrawerStyle === 'modern'
                                "
                            />

                            <toolbar-auth-links
                                v-if="!authUser"
                                :login="true"
                                :register="true"
                            />
                        </div>

                        <div class="d-flex align-center justify-end ml-2">
                            <toolbar-language :cssClass="''" v-if="isDesktop" />
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:extension>
            <app-tabs>
                <header-item
                    v-for="(headerItem, i) in headerItems"
                    :key="i"
                    :item="headerItem"
                />
            </app-tabs>
            <!--            <app-tabs v-if="mdAndDown" centered class="mx-2">-->
            <!--                <mobile-header-item-->
            <!--                    v-for="(mobileHeaderItem, i) in mobileHeaderItems"-->
            <!--                    :key="i" :item="mobileHeaderItem"-->
            <!--                />-->
            <!--            </app-tabs>-->
        </template>

        <v-dialog
            v-if="$auth.user() && $auth.user().active_bonus"
            v-model="freebet_dialog"
            max-width="600"
            dark
        >
            <v-card class="pb-2">
                <v-card-title class="headline">
                    {{ $t("dashboard.sections.bonuses.freebetDetails") }}
                </v-card-title>

                <v-card-text class="d-flex align-center mb-2">
                    <div class="text-subtitle-1">
                        {{ $t("dashboard.sections.bonuses.couponAmount") }}:
                    </div>
                    <div class="text-subtitle-1 font-weight-bold ml-2">
                        {{
                            $auth.user().active_bonus.settings
                                .freebet_coupon_amount / 100
                        }}
                    </div>
                </v-card-text>

                <v-card-text class="d-flex align-center mb-2">
                    <div class="text-subtitle-1">
                        {{
                            $t(
                                "dashboard.sections.bonuses.minimumOddsForSingleBets"
                            )
                        }}:
                    </div>
                    <div class="text-subtitle-1 font-weight-bold ml-2">
                        {{ $auth.user().active_bonus.settings.single_bet }}
                    </div>
                </v-card-text>

                <v-card-text class="d-flex align-center mb-2">
                    <div class="text-subtitle-1">
                        {{
                            $t(
                                "dashboard.sections.bonuses.minimumOddsForMultipleBets"
                            )
                        }}:
                    </div>
                    <div class="text-subtitle-1 font-weight-bold ml-2">
                        {{ $auth.user().active_bonus.settings.multiple_bet }}
                    </div>
                </v-card-text>

                <!--                <v-card-text class="d-flex align-center mb-2">-->
                <!--                    <div class="text-subtitle-1">{{ $t('dashboard.sections.bonuses.restrictedSports') }}:</div>-->
                <!--                    <div class="text-subtitle-1 font-weight-bold ml-2"></div>-->
                <!--                </v-card-text>-->

                <!--                <v-card-text class="d-flex align-center mb-2">-->
                <!--                    <div class="text-subtitle-1">{{ $t('dashboard.sections.bonuses.restrictedMarkets') }}:</div>-->
                <!--                    <div class="text-subtitle-1 font-weight-bold ml-2"></div>-->
                <!--                </v-card-text>-->

                <v-card-text class="d-flex align-center mb-2">
                    <div class="text-subtitle-1">
                        {{ $t("dashboard.sections.bonuses.notice") }}:
                    </div>
                    <div class="text-subtitle-1 font-weight-bold ml-2">
                        {{ $t("dashboard.sections.bonuses.freebetNotice") }}
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="tile-gradient-dark"
                        text
                        @click="freebet_dialog = false"
                    >
                        {{ $t("common.close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-if="$auth.user() && $auth.user().active_bonus"
            v-model="freespin_dialog"
            max-width="600"
            dark
        >
            <v-card class="pb-2">
                <v-card-title class="headline">
                    {{ $t("dashboard.sections.bonuses.freespinDetails") }}
                </v-card-title>

                <v-card-text class="d-flex align-center mb-2">
                    <div class="text-subtitle-1">
                        {{ $t("dashboard.sections.bonuses.freespinsAmount") }}:
                    </div>
                    <div class="text-subtitle-1 font-weight-bold ml-2">
                        {{
                            $auth.user().active_bonus.settings.freespin_quantity
                        }}
                    </div>
                </v-card-text>

                <v-card-text
                    v-if="
                        freespinAllowedProviders &&
                            freespinAllowedProviders.length
                    "
                >
                    <div class="text-subtitle-1 mb-2">
                        {{
                            $t(
                                "dashboard.sections.bonuses.allowedGameProviders"
                            )
                        }}:
                    </div>
                    <span
                        class="ml-2"
                        v-for="provider in freespinAllowedProviders"
                        :key="provider.id"
                    >
                        <router-link
                            :to="'/casino/' + provider.slug"
                            class="text-subtitle-1 font-weight-bold"
                            >{{ provider.original_name }},</router-link
                        >
                    </span>
                </v-card-text>

                <v-card-text
                    v-if="freespinAllowedGames && freespinAllowedGames.length"
                >
                    <div class="text-subtitle-1 mb-2">
                        {{ $t("dashboard.sections.bonuses.allowedGames") }}:
                    </div>
                    <div
                        class="d-flex flex-wrap justify-space-between justify-lg-start"
                    >
                        <router-link
                            v-for="game in freespinAllowedGames"
                            :key="game.id"
                            :to="
                                '/casino/' +
                                    (game.game_provider
                                        ? game.game_provider.slug
                                        : 'provider') +
                                    '/' +
                                    game.slug
                            "
                            class="freespin-game"
                        >
                            <v-img
                                max-width="150"
                                class="rounded elevation-1"
                                aspect-ratio="1"
                                :src="getImage(game, 'public', 200)"
                                :lazy-src="getImage(game, '250x250', 50)"
                                position="center top"
                            >
                            </v-img>
                        </router-link>
                    </div>
                </v-card-text>

                <v-card-text
                    v-if="
                        freespinAllowedCategories &&
                            freespinAllowedCategories.length
                    "
                >
                    <div class="text-subtitle-1 mb-2">
                        {{
                            $t("dashboard.sections.bonuses.allowedCategories")
                        }}:
                    </div>
                    <span
                        class="ml-2"
                        v-for="category in freespinAllowedCategories"
                        :key="category.id"
                    >
                        <router-link
                            :to="'/casino/category/' + category.slug"
                            class="text-subtitle-1 font-weight-bold"
                        >
                            {{
                                category.name[current_lang]
                                    ? category.name[current_lang]
                                    : category.name.en
                            }},
                        </router-link>
                    </span>
                </v-card-text>

                <v-card-text v-if="!freespinIsRealMoney">
                    <div class="text-subtitle-1 mb-2">
                        {{
                            $t(
                                "dashboard.sections.bonuses.turnoverRequirements"
                            )
                        }}:
                        {{
                            $auth.user().active_bonus.settings
                                .rollover_requirement
                        }}x
                        {{
                            $t(
                                "dashboard.sections.bonuses.turnoverRequirementsText"
                            )
                        }}
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="tile-gradient-dark"
                        text
                        @click="freespin_dialog = false"
                    >
                        {{ $t("common.close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

import LogoResponsive from "@/components/common/LogoResponsive";
import AppTabs from "@/components/navigation/AppTabs";
import ToolbarUser from "@/components/toolbar/ToolbarUser";
import ToolbarBalanceStatic from "@/components/toolbar/ToolbarBalanceStatic";
import ToolbarCryptoBalance from "@/components/toolbar/ToolbarCryptoBalance.vue";
import ToolbarLanguage from "@/components/toolbar/ToolbarLanguage";
import ToolbarAuthLinks from "@/components/toolbar/ToolbarAuthLinks";
import breaks from "@/mixins/breaks";
import HeaderItem from "./HeaderItem";
import MobileHeaderItem from "./MobileHeaderItem";
import ToolbarDepositWithdrawal from "./ToolbarDepositWithdrawal";
import moment from "moment";
import ToolbarUserModern from "./ToolbarUserModern";
import ToolbarChat from "@/components/toolbar/ToolbarChat.vue";
import LeftMenu from "@/components/user/LeftMenu.vue";
import ToolbarCryptoWalletLinks from "@/components/toolbar/ToolbarCryptoWalletLinks.vue";
import ToolbarBonus from "@/components/toolbar/ToolbarBonus.vue";

export default {
    name: "Toolbar",
    components: {
        ToolbarBonus,
        ToolbarCryptoWalletLinks,
        ToolbarCryptoBalance,
        LeftMenu,
        ToolbarChat,
        HeaderItem,
        MobileHeaderItem,
        LogoResponsive,
        AppTabs,
        ToolbarUser,
        ToolbarUserModern,
        ToolbarBalanceStatic,
        ToolbarLanguage,
        ToolbarAuthLinks,
        ToolbarDepositWithdrawal
    },
    mixins: [breaks],
    data: () => ({
        isActiveBurgerMenu: false,
        loggedIn: true,
        time: "",
        timeInterval: "",
        freespin_dialog: false,
        freebet_dialog: false,
        bonus_amount: 0,
        freespinAllowedProviders: [],
        freespinAllowedGames: [],
        freespinAllowedCategories: []
    }),
    created() {
        this.timeInterval = setInterval(this.currentTime, 1000);
    },
    computed: {
        height() {
            return this.$vuetify.breakpoint.xsOnly
                ? { main: 45, extension: 75 }
                : { main: 85, extension: 45 };
        },
        freebetBonus() {
            return (
                !Object.keys(this.bonus_deposit).length &&
                this.userActiveBonus &&
                Object.keys(this.userActiveBonus).length &&
                this.userActiveBonus.type === "freebet"
            );
        },
        freespinBonus() {
            return (
                !Object.keys(this.bonus_deposit).length &&
                this.userActiveBonus &&
                Object.keys(this.userActiveBonus).length &&
                this.userActiveBonus.type === "freespin" &&
                +this.userActiveBonus.status === 1 &&
                this.bonus_target < 100
            );
        },
        hasCorrectFreeSpinBonusWalletCombination() {
            if (this.$auth.user() && this.$auth.user().active_bonus) {
                const user = this.$auth.user()
                const bonus = user.active_bonus.bonus || user.active_bonus

                if (user.active_bonus.type !== "freespin") {
                    return true
                }

                if (!bonus || !bonus.settings) {
                    return true
                }

                if (bonus.settings.freespin_multi_currencies) {
                    const data = bonus.settings.freespin_multi_currencies
                    if (Array.isArray(data)) {
                        const found = data.find((setting) =>
                                                            setting['currency'] === user.fiatBalance.currency
                                                            &&
                                                            setting['crypto_currency'] === user.cryptoBalance.currency)
                        if (found) {
                            return true
                        }
                    }
                }

                const hasCrypto = user.cryptoBalance.currency === bonus.settings.freespin_crypto_currency
                const hasFiat = user.fiatBalance.currency === bonus.settings.freespin_currency

                return hasFiat && hasCrypto
            }

            return true
        },
        userActiveBonus() {
            return this.$auth.user() && this.$auth.user().active_bonus;
        },
        freespinIsRealMoney() {
            return this.$auth.user().active_bonus.settings
                .freespin_is_real_money;
        },
        ...mapState({
            settings: state => state.app.brandSettings,
            images_cdn: state => state.app.brandSettings.images_cdn,
            profileDrawerStyle: state => state.app.brandSettings.profile_drawer_style,
            userAmount: state => state.user.amount,
            userBonusAmount: state => state.user.bonus_amount,
            userTotalAmount: state => state.user.total_amount < 0 ? 0 : state.user.total_amount,
            bonus_target: state => state.user.bonus_target,
            bonus_deposit: state => state.user.bonus_deposit
        }),
        ...mapState("app", [
            "toolbarTheme",
            "isToolbarDetached",
            "controlContents"
        ]),
        ...mapState({
            current_lang: state => state.user.current_lang,
            bonusUpdateKey: state => state.app.bonusUpdateKey
        }),
        ...mapState({
            headerSettings: state =>
                state.app.controlContents.hasOwnProperty("header")
                    ? state.app.controlContents.header.settings
                    : null,
            headerMobileSettings: state =>
                state.app.controlContents.hasOwnProperty("mobileHeader")
                    ? state.app.controlContents.mobileHeader.settings
                    : null,
            headerSettingsFirstAuth: state =>
                state.app.controlContents.hasOwnProperty("header") &&
                state.app.controlContents.header.settings.first &&
                state.app.controlContents.header.settings.first.auth
                    ? state.app.controlContents.header.settings.first.auth
                    : null,
            headerSettingsFirstNoAuth: state =>
                state.app.controlContents.hasOwnProperty("header") &&
                state.app.controlContents.header.settings.first &&
                state.app.controlContents.header.settings.first.noauth
                    ? state.app.controlContents.header.settings.first.noauth
                    : null,
            headerSettingsSecondAuth: state =>
                state.app.controlContents.hasOwnProperty("header") &&
                state.app.controlContents.header.settings.second &&
                state.app.controlContents.header.settings.second.auth
                    ? state.app.controlContents.header.settings.second.auth
                    : null,
            headerSettingsSecondNoAuth: state =>
                state.app.controlContents.hasOwnProperty("header") &&
                state.app.controlContents.header.settings.second &&
                state.app.controlContents.header.settings.second.noauth
                    ? state.app.controlContents.header.settings.second.noauth
                    : null,

            headerMobileSettingsFirstAuth: state =>
                state.app.controlContents.hasOwnProperty("mobileHeader") &&
                state.app.controlContents.mobileHeader.settings.first &&
                state.app.controlContents.mobileHeader.settings.first.auth
                    ? state.app.controlContents.mobileHeader.settings.first.auth
                    : null,
            headerMobileSettingsFirstNoAuth: state =>
                state.app.controlContents.hasOwnProperty("mobileHeader") &&
                state.app.controlContents.mobileHeader.settings.first &&
                state.app.controlContents.mobileHeader.settings.first.noauth
                    ? state.app.controlContents.mobileHeader.settings.first
                          .noauth
                    : null,
            headerMobileSettingsSecondAuth: state =>
                state.app.controlContents.hasOwnProperty("mobileHeader") &&
                state.app.controlContents.mobileHeader.settings.second &&
                state.app.controlContents.mobileHeader.settings.second.auth
                    ? state.app.controlContents.mobileHeader.settings.second
                          .auth
                    : null,
            headerMobileSettingsSecondNoAuth: state =>
                state.app.controlContents.hasOwnProperty("mobileHeader") &&
                state.app.controlContents.mobileHeader.settings.second &&
                state.app.controlContents.mobileHeader.settings.second.noauth
                    ? state.app.controlContents.mobileHeader.settings.second
                          .noauth
                    : null,

            headerItems: state =>
                state.app.controlContents.hasOwnProperty("header")
                    ? state.app.controlContents.header.items.filter(
                          v => v.active === 1
                      )
                    : [],
            mobileHeaderItems: state =>
                state.app.controlContents.hasOwnProperty("mobileHeader") &&
                state.app.controlContents.mobileHeader.items
                    ? state.app.controlContents.mobileHeader.items.filter(
                          v => v.active === 1
                      )
                    : []
        }),
        authUser() {
            return this.$auth.user();
        }
    },
    methods: {
        getFreebetInfo() {
            this.freebet_dialog = true;
        },
        getFreespinInfo() {
            axios
                .post("/api/freespin_info", {
                        providers: this.$auth.user().active_bonus.settings
                            .providers,
                        games: this.$auth.user().active_bonus.settings
                            .freespin_games,
                        categories: this.$auth.user().active_bonus.settings
                            .categories
                })
                .then(res => {
                    this.freespin_dialog = true;
                    this.freespinAllowedProviders = res.data.providers;
                    this.freespinAllowedGames = res.data.games;
                    this.freespinAllowedCategories = res.data.categories;
                });
        },
        getImage(gameItem, cloudflare_size, cdn_size) {
            if (this.mdAndUp) {
                if (gameItem.banner_cloudflare) {
                    return gameItem.banner_cloudflare + cloudflare_size;
                } else {
                    return this.images_cdn + cdn_size + "/" + gameItem.banner;
                }
            } else {
                if (gameItem.mobile_banner_cloudflare) {
                    return gameItem.mobile_banner_cloudflare + cloudflare_size;
                } else {
                    return (
                        this.images_cdn +
                        cdn_size +
                        "/" +
                        gameItem.mobile_banner
                    );
                }
            }
        },
        currentTime() {
            this.time = moment()
                .locale(this.current_lang)
                .format("HH:mm dddd DD MMMM YYYY");
        },
        openNavigation() {
            this.$emit("open-navigation");
        },
        openProfile() {
            this.$emit("open-profile");
        },
        closeBurgerMenu() {
            this.isActiveBurgerMenu = false;
        }
    }
};
</script>

<style lang="scss">
.main-toolbar {
    overflow: hidden;

    @media screen and (max-width: 600px) {
        height: auto !important;
    }

    .main-toolbar__buttons {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
        align-items: center;
        flex: 1 0 auto;

        @media screen and (max-width: 600px) {
            gap: 0;
        }

        .none-400 {
            @media screen and (max-width: 359px) {
                display: none !important;
            }
        }
    }

    .v-toolbar__extension {
        .v-slide-group {
            justify-content: space-between;

            .main-toolbar-header__item:first-of-type {
                margin-left: 0 !important;
                //padding-left: 0 !important;
            }

            .main-toolbar-header__item:last-of-type {
                margin-right: 0 !important;
                //padding-right: 0 !important;
            }

            .main-toolbar-header__item--right {
                //flex-grow: 1;
                margin-left: auto;
            }

            .v-slide-group__wrapper {
                flex-grow: 0;
            }

            .v-slide-group__prev,
            .v-slide-group__next {
                display: none;
            }
        }
    }

    .v-tabs-bar {
        display: block !important;

        //.main-toolbar-header__item:first-of-type .v-icon:before{
        //    margin-left: 0 !important;
        //    padding-left: 0 !important;
        //}
    }

    .v-slide-group__wrapper {
        display: block !important;
        height: 100%;
        margin-left: -16px;
        margin-right: -10px;

        @media screen and (max-width: 1300px) {
            margin-left: -28px;
            margin-right: -28px;
        }
    }

    .v-tabs-bar__content {
        height: 100% !important;
        width: fit-content;
    }
}

//.header-spacer {
//    height: 40px;
//    width: 2px;
//    background-color: rgba(215, 218, 224, 0.30);
//}

.header-column {
    margin: 0 4px;
}
.freespin-game {
    max-width: 24%;
    width: 100%;
    margin-right: 6px;
    margin-bottom: 6px;

    &:nth-child(4n) {
        margin-right: 0;
    }

    @include media("md-and-down") {
        max-width: 49%;
        margin-right: 0;
    }
}

//.cashback-icon {
//    animation: wobble 2s ease-in-out infinite;
//}

@keyframes wobble {
    0% {
        transform: translateZ(0);
    }
    15% {
        transform: translate3d(-25%, 0, 0) rotate(-5deg);
    }
    30% {
        transform: translate3d(20%, 0, 0) rotate(3deg);
    }
    45% {
        transform: translate3d(-15%, 0, 0) rotate(-3deg);
    }
    60% {
        transform: translate3d(10%, 0, 0) rotate(2deg);
    }
    75% {
        transform: translate3d(-5%, 0, 0) rotate(-1deg);
    }
    to {
        transform: translateZ(0);
    }
}
.main-toolbar-links-wrap {
    flex: 1 0 auto;
}

.v-toolbar__content {
    max-width: 1300px;
    margin: 0 auto;
    padding: 16px 30px !important;

    @media screen and (max-width: 600px) {
        padding: 8px 15px 0 !important;
    }
}

.v-app .v-toolbar__extension {
    display: block !important;
}

.main-toolbar .v-slide-group__content {
    padding: 0 !important;
}

.header-tabs {
    max-width: 1300px;
    margin: 0 auto !important;
    padding: 0 30px !important;

    @media screen and (max-width: 600px) {
        align-items: center;
        display: flex;
        margin-top: -5px !important;
        flex-direction: row;
    }
}

.v-toolbar__content {
    display: block !important;
}

.app-bar__top {
    display: flex;
    justify-content: space-between;
    height: 38px;
    margin-top: -3px;
}
</style>
