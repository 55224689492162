export const MODALS = {
    CONFIRMATION: 'confirmation',
    INFO: 'info',
    REGISTER: 'register',
    LOGIN: 'login',
    WALLET: 'wallet',
    FORGOT: 'forgot',
    VERIFY_EMAIL: 'verifyEmail',
    VERIFY_EMAIL_BEFORE_LOGIN: 'verifyEmailBeforeLogin',
    VERIFY_EMAIL_SUCCESS: 'verifyEmailSuccess',
    BET_TIMEOUT: 'betTimeout',
    USER_DASHBOARD_MOBILE: 'userDashboardMobile',
    ERROR: 'error',
}

export const WALLET_TABS = {
    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdrawal',
    BUY_CRYPTO: 'buy_crypto',
}
