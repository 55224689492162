<template>
    <div v-if="item.type === 'dropdown'">
        <v-menu
            v-model="isMenuOpen"
            offset-y
            class="text-center rounded-0"
        >
            <template v-slot:activator="{ on, attrs, value }">
                <v-tab
                    v-bind="attrs"
                    :active-class="activeTabClassDropdown"
                    class="main-toolbar-header__item"
                    :class="{'main-toolbar-header__item--right': 0 && item.link === '/promotions', [activeTabClassDropdown] : true}"
                    v-on="on"
                >
                    <span v-if="item.image" class="">
                        <v-img class="header-item__image" :height="imageHeight" :width="imageWidth" :src="item.image" :class="{ 'active-tab': isActiveTab }"/>
                    </span>
                    <span v-else-if="item.icon" class="mr-1">
                         <v-icon class="mb-1" medium>{{ item.icon }}</v-icon>
                     </span>

                    <span>{{ item.title }}</span>
                    <v-badge
                        v-if="item.badge"
                        overlap
                        offset-x="55"
                        offset-y="-5"
                        class="header-badge"
                        dark
                        color="red"
                        :content="item.badge.toUpperCase()">
                        <span></span>
                    </v-badge>
                </v-tab>
            </template>

            <v-list tile dark class="primary">
                <v-list-item
                    v-for="(dropdownItem, index) in item.dropdownItems"
                    :to="dropdownItem.link"
                    :target="dropdownItem.link_type === 'external' ? '_blank' : '_self'"
                    :key="index">
                    <v-badge
                        v-if="dropdownItem.badge"
                        dot
                        color="red"
                        :content="dropdownItem.badge.toUpperCase()">
                        <v-list-item-title class="d-flex">
                          <span v-if="dropdownItem.icon" class="mr-1">
                              <v-icon class="mb-1" small>{{ dropdownItem.icon }}</v-icon>
                          </span>

                            <span v-if="dropdownItem.image" class="mr-1">
                              <v-img max-width="30" :lazy-src="dropdownItem.image" :src="dropdownItem.image"></v-img>
                          </span>

                            <span>{{ dropdownItem.title }}</span>
                        </v-list-item-title>
                    </v-badge>

                    <template v-else>
                        <v-list-item-title class="d-flex">
                          <span v-if="dropdownItem.icon" class="mr-1">
                              <v-icon class="mb-1" small>{{ dropdownItem.icon }}</v-icon>
                          </span>
                            <span v-if="dropdownItem.image" class="mr-1">
                              <v-img max-width="30" :lazy-src="dropdownItem.image" :src="dropdownItem.image"></v-img>
                          </span>
                            <span>{{ dropdownItem.title }}</span>
                        </v-list-item-title>
                    </template>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>

    <v-tab v-else
           @click="toggleActive"
           class="main-toolbar-header__item"
           :class="{'main-toolbar-header__item--right': 0 && item.link === '/promotions'}"
           :target="item.link_type === 'external' ? '_blank' : '_self'"
           :to="item.link"
    >

        <div class="v-tab__background"></div>

        <!--        :class="{ 'black-active-image': isActiveTab }"-->
        <span v-if="item.image" class="mr-2">
            <v-img class="header-item__image" :height="imageHeight" :width="imageWidth" :src="item.image" :class="{ 'active-tab': isActiveTab }"/>
        </span>
        <span v-else-if="item.icon" class="mr-2">
            <v-icon medium>{{ item.icon.replace('mdi-', '') }}</v-icon>
        </span>

        <span class="" style="width: max-content">{{ item.title }}</span>

        <v-badge
            v-if="item.badge"
            overlap
            offset-x="55"
            offset-y="-5"
            class="header-badge"
            dark
            color="red"
            :content="item.badge.toUpperCase()"
        >
            <span></span>
        </v-badge>
    </v-tab>

</template>

<script>
import {mapState} from "vuex"

export default {
    name: "HeaderItem",
    props: ["item"],
    computed: {
        ...mapState({
            translationSettings: state => state.app.translationSettings
        }),
        imageHeight() {
            return 20; //this.$vuetify.breakpoint.xsOnly ? 14 :
        },
        imageWidth() {
            return 19;
        },
        activeTabClassDropdown() {
            return this.isMenuOpen
                || (this.item?.dropdownItems || []).find(item => this.$route.fullPath.startsWith(item.link))
                    ? 'v-tab--active'
                    : 'dropdown-active-false';
        }

    },
    data: () => ({
        isActiveTab: false,
        isMenuOpen: false,
    }),
    methods: {
        toggleActive() {
            this.isActiveTab = true
        }
    }
}
</script>

<style lang="scss">

.main-toolbar-header__item {
    flex-direction: column;
}

.main-toolbar .app-tabs .v-tab {

    color: white !important;

    .v-icon {
        color: white !important;
    }

    &.v-tab--active.dropdown-active-false {
        color: white !important;

        &:hover {
            background-color: var(--v-header-tab-active-base) !important;
            color: var(--v-slots-title-base) !important;

            .v-icon {
                color: var(--v-slots-title-base) !important;
            }
        }
    }
    &.v-tab--active:not(.dropdown-active-false), &:hover {
        background-color: var(--v-header-tab-active-base) !important;
        color: var(--v-slots-title-base) !important;

        .v-icon {
            color: var(--v-slots-title-base) !important;
        }
    }


    &:hover {
        color: var(--v-slots-title-base) !important;

        .v-icon {
            color: var(--v-slots-title-base) !important;
        }
    }

}

.black-active-image {
    filter: brightness(0%);
}

@media screen and (max-width: 600px) {
    .main-toolbar .v-toolbar__extension {
        //height: 75px !important;
        border-top: solid 5px var(--v-primary-base) !important;
        padding-left: 10px;
    }
}

.header-item__image .v-image__image {
    background-position: center top !important;
}

.main-toolbar-header__item.v-tab--active .header-item__image .v-image__image,
    .main-toolbar-header__item:hover .header-item__image .v-image__image {
    background-position: center bottom !important;
}
</style>
