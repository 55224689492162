<template>
    <v-card class="pa-4 user__left-menu header">
        <div class="py-0 pl-3 font-weight-medium">
            {{ $t("dashboard.sidebarParameters.welcome") }}, {{ fullName }}
        </div>

        <v-row no-gutters class="mt-4">
            <v-col cols="6" class="pr-2">
                <v-btn
                    block
                    class="button--gray px-4"
                    @click="showWallet('withdrawal')"
                >
                    {{ $t("dashboard.sidebarParameters.withdraw") }}
                </v-btn>
            </v-col>
            <v-col cols="6" class="pl-2">
                <v-btn
                    block
                    class="tile-gradient-primary px-4"
                    @click="showWallet('deposit')"
                >
                    {{ $t("dashboard.sidebarParameters.deposit") }}
                </v-btn>
            </v-col>
        </v-row>

        <div class="user__left-menu-balance mt-4">
            <v-row class="d-flex">
                <v-col class="d-flex flex-column align-center justify-center ml-4">
                    <div class="font-weight-medium">
                        {{ balance }}
                    </div>
                    <div class="text-caption">
                        {{ $t("dashboard.sidebarParameters.balance") }}
                    </div>
                    <!--                    <div class="user__left-menu-balance-card">-->
                    <!--                        <div class="user__left-menu-balance-card-balance">-->
                    <!--                            0-->
                    <!--                        </div>-->
                    <!--                        <div class="user__left-menu-balance-card-label">-->
                    <!--                            {{ $t("dashboard.sidebarParameters.spin") }}-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </v-col>
                <v-col cols="1" class="d-flex align-center justify-center">
                    <v-btn
                        icon small
                        @click="refreshBalance"
                    >
                        <custom-refresh-icon
                            :rotate="loadingBalance"
                            size="16"
                        />
                    </v-btn>
                </v-col>
                <v-col class="d-flex flex-column align-center justify-center mr-4">
                    <div class="font-weight-medium">
                        {{ bonusBalance }}
                    </div>
                    <div class="text-caption">
                        {{ $t("dashboard.sidebarParameters.bonus") }}
                    </div>
                    <!--                    <div class="user__left-menu-balance-card">-->
                    <!--                        <div class="user__left-menu-balance-card-balance">-->
                    <!--                            0-->
                    <!--                        </div>-->
                    <!--                        <div class="user__left-menu-balance-card-label">-->
                    <!--                            {{ $t("dashboard.sidebarParameters.bet") }}-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </v-col>
            </v-row>
            <!--                <v-btn-->
            <!--                    class="button&#45;&#45;gray mb-4"-->
            <!--                    width="100%"-->
            <!--                    to="/user/deposit"-->
            <!--                    @click="linkClick()"-->
            <!--                >-->
            <!--                    {{ $t("dashboard.sidebarParameters.cashback") }}-->
            <!--                </v-btn>-->
        </div>

        <!--        <v-navigation-drawer-->
        <!--            floating-->
        <!--            permanent-->
        <!--            width="100%"-->
        <!--            height="auto"-->
        <!--            class=""-->
        <!--        >-->
        <v-list nav dense min-width="293" class="px-0 transparent">
            <v-list-item-group v-model="selectedItem">
                <template v-for="(item, itemIndex) in items">
                    <v-list-group
                        v-if="item.type === 'dropdown' && item.items.length"
                        :key="itemIndex"
                        :prepend-icon="item.icon"
                        :value="item.items.some(i => i.link === $route.path || i.link === activePath)"
                    >
                        <template v-slot:activator>
                            <v-list-item-title> {{ $t(item.title) }}</v-list-item-title>
                        </template>

                        <v-list-item
                            v-for="(subItem, subItemIndex) in item.items"
                            :key="`${itemIndex}-${subItemIndex}`"
                            :to="subItem.link"
                            @click="linkClick(subItem)"
                            class="px-6"
                            :class="{'list-active': activePath === subItem.link}"
                            :input-value="subItem.link && activePath === subItem.link"
                        >
                            <!--                            <v-list-item-icon>-->
                            <!--                                <v-icon v-text="subItem.icon"></v-icon>-->
                            <!--                            </v-list-item-icon>-->

                            <!--                            <v-list-item-content>-->
                            <v-list-item-title>{{ $t(subItem.title) }}</v-list-item-title>
                            <!--                            </v-list-item-content>-->

                            <!--                            <v-list-item-icon>-->
                            <!--                                <v-icon>mdi-chevron-down</v-icon>-->
                            <!--                            </v-list-item-icon>-->
                        </v-list-item>
                    </v-list-group>

                    <v-list-item
                        v-else-if="item.type === 'messages'"
                        :key="itemIndex"
                        :to="item.link"
                        @click="linkClick(item)"
                        :class="{'list-active': activePath === item.link}"
                        :input-value="activePath === item.link"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item-content>

                        <!--                        <v-list-item-icon style="align-items: center">-->
                        <!--                            <div class="user__left-menu-messages-counter">-->
                        <!--                                7-->
                        <!--                            </div>-->
                        <!--                        </v-list-item-icon>-->
                    </v-list-item>

                    <v-list-item
                        v-else
                        :key="itemIndex"
                        :to="item.link"
                        @click="linkClick(item)"
                        :class="{'list-active': activePath === item.link}"
                        :input-value="activePath === item.link"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item-content>

                        <!--                        <v-list-item-icon>-->
                        <!--                            <v-icon>mdi-chevron-down</v-icon>-->
                        <!--                        </v-list-item-icon>-->
                    </v-list-item>

                </template>

                <v-list-item
                    color="gray-2"
                    @click="logout"
                >
                    <v-list-item-icon>
                        <v-icon>betvamos-logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ $t("menu.logout") }}</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
            </v-list-item-group>
        </v-list>
        <!--        </v-navigation-drawer>-->
        <!--        <in-menu-banner/>-->
    </v-card>
</template>

<script>

import {mapState, mapActions} from "vuex";
import Items from "@/configs/menus/user.menu"
import InMenuBanner from "@/components/user/InMenuBanner.vue";
import CustomRefreshIcon from "@/components/ui/CustomRefreshIcon.vue";

export default {
    components: {CustomRefreshIcon, InMenuBanner},
    name: 'LeftMenu',
    props: {
        activePath: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        selectedItem: 0,
        items: Items,
        loadingBalance: false,
        balancePlaceholder: '***.**'
    }),
    computed: {
        balance() {
            if (this.currencySymbol === 'JPY') {
                return this.hideBalance ? this.balancePlaceholder : this.currencySymbol + ' ' + parseFloat(this.userAmount).toFixed(0);
            }
            return this.hideBalance ? this.balancePlaceholder : this.currencySymbol + ' ' + this.userAmount;
        },
        bonusBalance() {
            if (this.currencySymbol === 'JPY') {
                return this.hideBalance ? this.balancePlaceholder : this.currencySymbol + ' ' + parseFloat(this.userBonusAmount).toFixed(0);
            }
            return this.hideBalance ? this.balancePlaceholder : this.currencySymbol + ' ' + this.userBonusAmount;
        },
        currencySymbol() {
            return this.currency?.currencySymbol ? this.currency.currencySymbol : this.$auth.user().currency;
        },
        currency() {
            return this.availableCurrencies.find(c => {
                return c.label === this.$auth.user().currency
            });
        },
        fullName() {
            const {firstname, lastname, username} = this.$auth.user()
            return firstname && lastname ? `${firstname} ${lastname}` : username
        },
        ...mapState({
            userAmount: state => state.user.amount,
            userBonusAmount: state => state.user.bonus_amount,
            userTotalAmount: state => state.user.total_amount,
            availableCurrencies: state => state.app.availableCurrencies,
            hideBalance: state => state.user.hide_balance,
        }),
    },
    methods: {
        ...mapActions({
            setBalance: 'user/setBalance',
            toggleVisibilityBalance: 'user/toggleVisibilityBalance',
            makeActiveWindow: 'app/makeActiveWindow',
        }),
        async refreshBalance() {
            this.loadingBalance = true;
            await this.$auth.fetch();
            this.setBalance({
                amount: this.$auth.user().amount,
                amount_bonus: this.$auth.user().amount_bonus
            });
            this.loadingBalance = false;
        },
        linkClick(item = null) {
            if (item && item.function) {
                item.function(this);
            }
            this.$emit('click');
        },
        showWallet(tab) {
            this.makeActiveWindow({type: 'wallet', active: tab});
            this.linkClick();
        },
        async logout() {
            await this.$auth.logout({});
            this.$store.commit('user/setLogin', false)
        }
    },
};
</script>

<style lang="scss">
.user__left-menu {
    .v-list-item__icon {
        min-width: auto;
    }

    .v-list-item__icon:first-child {
        margin-right: 8px;
    }

    .user__left-menu-messages-counter {
        height: 18px;
        padding: 0 9px;
        background-color: var(--v-primary-base);
        border-radius: 4px;

        color: var(--v-background-base);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}
</style>
