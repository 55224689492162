<template>
    <v-overlay v-if="info" v-model="info.active" z-index="203">
        <v-container max-width="600">
            <v-card class="pb-2 grey darken-4">
                <v-card-title class="headline mb-8">{{ info.title }}</v-card-title>
                <v-card-text class="d-flex align-center">
                    <div class="text-subtitle-1">{{ info.text }}</div>
                    <div v-if="info.data" class="text-subtitle-1 font-weight-bold ml-2">
                        {{ info.data }}
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" @click="closeWindow">
                        {{ $t("common.ok") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </v-overlay>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "Info",
    computed: {
        ...mapState({ info: state => state.app.windows.info }),
    },
    methods: {
        ...mapActions("app", ["makeActiveWindow"]),
        closeWindow() {
            this.makeActiveWindow({ active: false, type: "info" });
        },
    },
};
</script>
