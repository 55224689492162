import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=0d226c5e"
import script from "./Register.vue?vue&type=script&lang=js"
export * from "./Register.vue?vue&type=script&lang=js"
import style0 from "./Register.vue?vue&type=style&index=0&id=0d226c5e&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runcloud/webapps/demo-2-jetxplatform/releases/20250126165603/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d226c5e')) {
      api.createRecord('0d226c5e', component.options)
    } else {
      api.reload('0d226c5e', component.options)
    }
    module.hot.accept("./Register.vue?vue&type=template&id=0d226c5e", function () {
      api.rerender('0d226c5e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/modals/Register.vue"
export default component.exports